import { HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component } from '@angular/core';
import * as Survey from 'survey-angular';
import 'rxjs/add/operator/map';
import * as $ from 'jquery';
import { environment } from '../environments/environment';

/// ---------------------------------------------------------------
/// sendCompletedDataToServer
/// sends the response back to the server once the survey
/// has been completed
/// ---------------------------------------------------------------
function sendCompletedDataToServer(survey) {
  var data = survey.data;
  data.pageNo = survey.currentPageNo;

  var url = window.location.href;
  var id = url.match(/[^\/]+$/);
  var storageName = "patEx_";

  if (id != null) {
    storageName = storageName + id[0].toString();
  }

  window.localStorage.setItem(storageName, JSON.stringify(data));

  var resultAsString = JSON.stringify(generateAnswerObject(survey.data, true));

  //alert("Survey ID:" + number);
  sendJSONPackage(resultAsString, $("#patientQuestionnaireId").val().toString());
}

/// ---------------------------------------------------------------
/// sendDataToServer
/// sends the response back to the server after each page
/// is copmleted by the patient
/// ---------------------------------------------------------------
function sendDataToServer(survey) {
  var data = survey.data;
  data.pageNo = survey.currentPageNo;

  var url = window.location.href;
  var id = url.match(/[^\/]+$/);
  var storageName = "patEx_";

  if (id != null) {
    storageName = storageName + id[0].toString();
  }

  window.localStorage.setItem(storageName, JSON.stringify(data));

  var resultAsString = JSON.stringify(generateAnswerObject(survey.data, false));

  //alert("Survey ID:" + number);
  //console.log(resultAsString);
  sendJSONPackage(resultAsString, $("#patientQuestionnaireId").val().toString());
}

function generateAnswerObject(surveyAnswers, status : boolean ) {
  var answerObject : SurveyResponse = {
    "patientQuestionnaireId": $("#patientQuestionnaireId").val().toString(),
    "practiceId": $("#practiceId").val().toString(),
    "patientId": $("#patientId").val().toString(),
    "questionnaireId": $("#questionnaireId").val().toString(),
    "answers": [],
    "isComplete": status
  };

  var answerValue : string = "";
  var answerId : string = "";
  var answer : Answer;

  for (var key in surveyAnswers) {
    if (surveyAnswers.hasOwnProperty(key)) {
      //console.log(key);

      //split the value into an array by '-'
      var aArray = surveyAnswers[key].split('^');

      answerValue = "";
      answerId = "";

      //if the array is only length 1 then it is a free form answer
      //set the value and not the answer id
      if (aArray.length == 1) {
        answerValue = aArray[0];
        answerId = null;
      }

      //if the array is length 2 then there is an answer id and value
      //set both
      if (aArray.length > 1) {
        answerValue = aArray[1];
        answerId = aArray[0];
      }

      //initialze the page object
      answer = {
        "answerId": answerId,
        "questionId": key,
        "value": answerValue
      };

      //push it to the collection
      answerObject.answers.push(answer);
    }
  } //end for

  //console.log(answerObject);
  return answerObject;
}

/// ---------------------------------------------------------------
/// sendJSONPackage
/// this sends the patient response back to the server after
/// each page is submitted
/// ---------------------------------------------------------------
function sendJSONPackage(results : string, patientQuestionnaireId : string) {
  //var setResultsUrl = "https://api-dev.epreop.com/api/Questionnaires/Answer/SetPatientAnswers?";
  var setResultsUrl = $("#setAnswersUrl").val();

  var urlSubmitAnswers = setResultsUrl + "patientQuestionnaireId=" + patientQuestionnaireId;

  //console.log(urlSubmitAnswers);

  $.ajax({
    url: urlSubmitAnswers,
    type: "PUT",
    data: results,
    contentType: "application/json; charset=utf-8",
    success: function (data) {
      //alert("Successfully transmission of answers...");
    },
    error: function (xhRequest, ErrorText, thrownError) {
      //alert("Failed to process correctly, please try again...");
    }
  });
}

// --------------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------------

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
  //template: '<div id="surveyElement"></div>'
})

export class AppComponent {
  title = 'ePreop Patient Experience';
  data;
  rawData;

  //getSurveyUrl = "https://api-dev.epreop.com/api/Questionnaires/Questionnaire/GetPatientQuestionnaire?";

  surveyStatus = "";
  questionnaireId = "";
  practiceId = "";
  patientId = "";
  //patientQuestionnaireId = "FE2A679C-2D47-4431-9BD1-08D5A58178AD";
  patientQuestionnaireId = "XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXX1";

  setAnswersUrl = environment.setAnswersUrl;
  getSurveyUrl = environment.getSurveyUrl;

  errorOutput = "<div class=\"container\">" +
    "<div class=\"row\">" +
    //"<div class=\"col-4\">" +
    //"<img src=\"assets/images/metro-logo.png\" class=\"practice-logo\" />" +
    //"</div>" +
    "<div class=\"col-12\">" +
    "<h5 class=\"card-title\">Welcome to ePreop's Patient Experience survey.</h5>" +
    "</div>" +
    "</div>" +
    "<div class=\"row\">" +
    "<div class=\"col-12\">" +
    "<p class=\"card-text\">" +
    "Your Healthcare Provider would like you to take the following brief survey; it will only take a few minutes. " +
    "By participating, you will help ensure patients can receive the highest possible quality of care." +
    "</p>" +
    "</div>" +
    "</div>" +
    "</div><hr /><br />" +
    "<br />" +
    "<h2>Sorry, Survey Could Not Be Found</h2>";

  statusCompletedOutput = "<div class=\"container\">" +
    "<div class=\"row\">" +
    //"<div class=\"col-4\">" +
    //"<img src=\"assets/images/metro-logo.png\" class=\"practice-logo\" />" +
    //"</div>" +
    "<div class=\"col-12\">" +
    "<h5 class=\"card-title\">Welcome to ePreop's Patient Experience survey.</h5>" +
    "</div>" +
    "</div>" +
    "<div class=\"row\">" +
    "<div class=\"col-12\">" +
    "<p class=\"card-text\">" +
    "Your Healthcare Provider would like you to take the following brief survey; it will only take a few minutes. " +
    "By participating, you will help ensure patients can receive the highest possible quality of care." +
    "</p>" +
    "</div>" +
    "</div>" +
    "</div><hr /><br />" +
    "<h2><span style='color:#149BD5;' class='glyphicon glyphicon-send'></span>&nbsp;&nbsp;It appears that you've already completed your survey.<br /><br />" +
    "Thank you for your feedback.</h2>";

  constructor(private http: HttpClient) { }

  ngOnInit() {
    window.localStorage.clear();

    this.data = this.GetSurvey();
  }

  GetSurvey() {
    var promise = new Promise((resolve, reject) => {
      var url = window.location.href;
      var id = url.match(/[^\/]+$/);
      if (id != null) {
        this.patientQuestionnaireId = id[0].toString();

        //console.log("The URL of getSurvey:" + this.getSurveyUrl + "patientQuestionnaireId=" + this.patientQuestionnaireId);
        this.http.get(this.getSurveyUrl + "patientQuestionnaireId=" + this.patientQuestionnaireId)
          .map(res => res)
          .subscribe(data => {
            //console.log(data);

            this.modelMap(data);

            resolve(data);
          }, (error => {
            reject(error);
          }));
      }
      else {
        //show an error
        var errorMsg = this.errorOutput + "<i>Return Message: Not Valid Survey For User</i>";

        $("#surveyElement").html(errorMsg);
      }
    });
  }

  modelMap(JSONObject) {
    //console.log('Start modelMap');
    //setting up initial object

    var outputObject = {
      "locale": "en",
      "title": {
        "en": "ePreop Patient Experience Survey"
      },
      "completedHtml": {
        "en": "<span style='color:green;' class='glyphicon glyphicon-check'></span>&nbsp;&nbsp;Thank you for taking the time to fill out the survey.  Your feedback is very important to us."
      },
      "loadingHtml": {
        "en": "Please wait while the survey loads..."
      },
      "pages": [],
      "sendResultOnPageNext": true,
      "showNavigationButtons": false,
      "showPrevButton": true,
      "showTitle": false,
      "showPageTitles": false,
      "showQuestionNumbers": "off",
      "showProgressBar": "bottom",
      "goNextPageAutomatic": true
    };

    var errorEncountered = false;
    var alreadyCompleted = false;
    var errorMsg = "";

    if (JSONObject.items.length > 0) {
      //console.log("items found");

      this.patientId = JSONObject.items[0].patientId;
      this.practiceId = JSONObject.items[0].practiceId;
      this.questionnaireId = JSONObject.items[0].questionnaireId;
      this.surveyStatus = JSONObject.items[0].status;

      if (this.surveyStatus == "Completed") {
        //they have already done this... exit and display a message
        alreadyCompleted = true;
        errorEncountered = true;
      }
      else {
        var questionCount = Object.keys(JSONObject.items[0].questions).length;
        //console.log("question count: " + questionCount);

        var pageCount = (questionCount / 2) + 1;
        //console.log("page count: " + pageCount);

        //console.log("keys:");
        var pastFirstPage = false;
        var pageCounter = 1;
        var questionsPrinted = 0;
        var pageName = "page";
        var navigationVisibility = "hide";

        //right now we are assuming that the first question is always the required one...
        //we are not looking at the expressions array to see what behavior there might be
        //TODO: we need to fix this to drive off the expression array in the return object.
        var required = true;
        var QuestionsPerPage = 1;

        for (var key in JSONObject.items[0].questions) {
          //initialize a page object
          var pageObject : SurveyPage;

          // check if the property/key is defined in the object itself, not in parent
          if (key != "$id") { //becuase of .net framework generation....
            if (JSONObject.items[0].questions.hasOwnProperty(key)) {
              //console.log(key);

              pageName = "page" + pageCounter;

              if (questionsPrinted == 0) {
                //initialze the page object
                pageObject = {
                  "name": pageName,
                  "elements": [],
                  "navigationButtonsVisibility": navigationVisibility
                };

                if (!pastFirstPage) {
                  pageObject.elements.push({
                    "type": "html",
                    "name": "surveyFrontPage",
                    "html": {
                      "en": "test"
                    }
                  });
                }
              }

              //console.log("Before question type check");
              //console.log("question type: " + JSONObject.items[0].questions[key].type);
              switch (JSONObject.items[0].questions[key].type) {
                case 'TextChoice':
                  //questionType = "radiogroup";
                  var answers = [];
                  //for each answer in question.answer array
                  for (var answersKey in JSONObject.items[0].questions[key].answers) {
                    //console.log("answerKey: " + answersKey);
                    if (answersKey != "$id") { //becuase of .net framework generation....
                      //find the corresponding answer in answer lookup array
                      for (var masterAnswerKey in JSONObject.items[0].answers) {
                        if (JSONObject.items[0].answers[masterAnswerKey].id == JSONObject.items[0].questions[key].answers[answersKey].id) {
                          answers.push({
                            "value": JSONObject.items[0].answers[masterAnswerKey].id + "^" + JSONObject.items[0].answers[masterAnswerKey].value,
                            "text": JSONObject.items[0].answers[masterAnswerKey].text
                          });
                        }
                      }
                    }
                  }

                  if (answers.length > 0) {
                    //add the question to the elements array
                    pageObject.elements.push({
                      "type": "radiogroup",
                      "name": JSONObject.items[0].questions[key].id,
                      "title": {
                        "en": (JSONObject.items[0].questions[key].text)//.replace("rate your overall anesthesia experience", "<b>rate your overall anesthesia experience</b>")
                      },
                      "isRequired": required,
                      "choices": answers
                    });
                  }
                  break;
                case 'NumberChoice':
                  //questionType = "radiogroup";
                  var answers = [];
                  for (var answersKey in JSONObject.items[0].questions[key].answers) {
                    //find the corresponding answer in answer lookup array
                    for (var masterAnswerKey in JSONObject.items[0].answers) {
                      if (JSONObject.items[0].answers[masterAnswerKey].id == JSONObject.items[0].questions[key].answers[answersKey].id) {
                        answers.push({
                          "value": JSONObject.items[0].answers[masterAnswerKey].id + "^" + JSONObject.items[0].answers[masterAnswerKey].value,
                          "text": JSONObject.items[0].answers[masterAnswerKey].text
                        });
                      }
                    }
                  }

                  if (answers.length > 0) {
                    //add the question to the elements array
                    pageObject.elements.push({
                      "type": "radiogroup",
                      "name": JSONObject.items[0].questions[key].id,
                      "title": {
                        "en": (JSONObject.items[0].questions[key].text)//.replace("rate your overall anesthesia experience", "<b>rate your overall anesthesia experience</b>")
                      },
                      "isRequired": required,
                      "choices": answers
                    });
                  }
                  break;
                case 'Number':
                  //questionType = "text";
                  //add the question to the elements array
                  pageObject.elements.push({
                    "type": "text",
                    "name": JSONObject.items[0].questions[key].id,
                    "title": {
                      "en": JSONObject.items[0].questions[key].text
                    },
                    "isRequired": required,
                    "maxLength":10
                  });
                  break;
                case 'Date':
                  //questionType = "text";
                  //add the question to the elements array
                  pageObject.elements.push({
                    "type": "text",
                    "name": JSONObject.items[0].questions[key].id,
                    "title": {
                      "en": JSONObject.items[0].questions[key].text
                    },
                    "isRequired": required,
                    "maxLength":20
                  });
                  break;
                case 'Text':
                  //questionType = "comment";
                  //add the question to the elements array
                  pageObject.elements.push({
                    "type": "comment",
                    "name": JSONObject.items[0].questions[key].id,
                    "title": {
                      "en": JSONObject.items[0].questions[key].text
                    },
                    "isRequired": required,
                    "maxLength":1000
                  });
                  break;
                case 'Phone':
                  //questionType = "text";
                  //add the question to the elements array
                  pageObject.elements.push({
                    "type": "text",
                    "name": JSONObject.items[0].questions[key].id,
                    "title": {
                      "en": JSONObject.items[0].questions[key].text
                    },
                    "isRequired": required,
                    "maxLength":20
                  });
                default:

              }

              questionsPrinted++;

              if (pastFirstPage && questionsPrinted >= QuestionsPerPage) { //allows for 2 questions per page
                //add the page to the return object
                outputObject.pages.push(pageObject);

                //increment the page
                pageCounter++;
                questionsPrinted = 0;
              }
              else if (!pastFirstPage && questionsPrinted > 0) {
                //add the page to the return object
                outputObject.pages.push(pageObject);

                //increment the page
                pageCounter++;
                questionsPrinted = 0;
              }

              pastFirstPage = true;
              required = false;
              navigationVisibility = "show";
            }
          }
        }

        //apply survey header
        var i, j;

        //var pageCount = Object.keys(JSONObj.pages).length;
        //let pages = this.data.query.pages;
        //console.log(pages[Object.keys(pages)[0]].title);

        for (i = 0; outputObject.pages.length > i; i += 1) {
          if (outputObject.pages[i].name === 'page1') {
            //console.log(this.data.pages[i].name);

            for (j = 0; outputObject.pages[i].elements.length > j; j += 1) {
              if (outputObject.pages[i].elements[j].name === 'surveyFrontPage') {
                outputObject.pages[i].elements[j].html.en =
                  "<div class=\"container\">" +
                  "<div class=\"row\">" +
                  //"<div class=\"col-4\">" +
                  //"<img src=\"assets/images/metro-logo.png\" class=\"practice-logo\" />" +
                  //"</div>" +
                  //"<div class=\"col-8\">" +
                  "<div class=\"col-12\">" +
                  "<h5 class=\"card-title\">Welcome to ePreop's Patient Experience survey.</h5>" +
                  "</div>" +
                  "</div>" +
                  "<div class=\"row\">" +
                  "<div class=\"col-12\">" +
                  "<p class=\"card-text\">" +
                  "Your Healthcare Provider would like you to take the following brief survey; it will only take a few minutes. " +
                  "By participating, you will help ensure patients can receive the highest possible quality of care." +
                  "</p>" +
                  "</div>" +
                  "</div>" +
                  "</div><hr />\n";
              }
            }
          }
        }
      }
    }
    else {
      if (JSONObject.messages.length > 0) {
        var message = JSONObject.messages[0].message;

        var errorMsg = this.errorOutput + "<i>Return Message: 553.10</i>";

        errorEncountered = true;
      }
    }

    //console.log("here we go...");
    //console.log(JSON.stringify(outputObject));
    if (!errorEncountered) {
      this.data = outputObject;

      var survey = new Survey.Model(JSON.stringify(this.data));
      //Survey.Survey.cssType = "bootstrap";
      Survey.StylesManager.applyTheme("bootstrap");
      survey.onPartialSend.add(sendDataToServer);

      survey.onComplete.add(sendCompletedDataToServer);
      console.log("Before onAfterRenderPage");
      survey.onAfterRenderPage.add(function (result, options) {
        console.log("inside onAfterRenderPage");
        //page 1
        //puts classes on parent div of radio button
        $(".radio input[type='radio']").parent().addClass("element-animation1 btn btn-lg btn-primary btn-block");

        //adds span within parent div of radio button
        $(".radio input[type='radio']").parent().prepend("<span class=\"btn-label\"><i class=\"glyphicon glyphicon-chevron-right\"></i></span>");

        //wraps button in an extra div
        $(".radio .btn-label").wrap("<div class=\"button-div\"></div>");

        //next button updates
        $(".card-footer input[type='button']").addClass("btn btn-lg btn-secondary");
        //$(".card-footer input[type='button']").attr('value', 'SKIP');

        //question text
        $(".sv_qstn").addClass("");

        //setting up line breaks in question text
        $( "span" ).each(function( index ) {
          var textHolder = $(this).text();
          console.log($(this));
          //HTML line break and BOLD formatting
          $(this).html(textHolder.replace("[BREAK]", "<br />").replace("[BOLDSTART]", "<b>").replace("[BOLDEND]", "</b>"));
        });
      });

      var storageName = "patEx_" + this.patientQuestionnaireId;

      var prevData = window.localStorage.getItem(storageName) || null;
      if (prevData) {
          var data = JSON.parse(prevData);
          survey.data = data;
          if (data.pageNo) {
              survey.currentPageNo = data.pageNo;
          }
      }

      Survey.SurveyNG.render("surveyElement", { model: survey,css: "bootstrap" });
    }
    else {
      if(alreadyCompleted){
        $("#surveyElement").html(this.statusCompletedOutput);
      }
      else {
      $("#surveyElement").html(errorMsg);
      }
    }
  }


}
